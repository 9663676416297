import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData, putData } from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';
import moment from 'moment-timezone';
import connectBtn from '../../src/qbo_connect_btn.png';

function Authenticate() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    const authId = queryParams.get('authId');
    const addCompany = queryParams.get('addCompany');

    const [loading, setLoading] = useState(true);
    const [timeZoneOffset, setTimeZoneOffset] = useState();
    const [isUnique, setIsUnique] = useState();
    const [autoSyncronize, setAutoSyncronize] = useState();
    const [isUniqueDisabled, setIsUniqueDisabled] = useState(false);
    const [authenticateButtonVisible, setAuthenticateButtonVisible] = useState(true);
    const [saveButtonVisible, setSaveButtonVisible] = useState(false);
    const [authEntity, setAuthEntity] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setTimeZoneOffset(getTimezoneOffset());

        if (authId){
            loadAuthentication();
        }

        if (addCompany)
        {
            setIsUniqueDisabled(true);
        }

        setLoading(false);
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once
      
    const loadAuthentication = async () => {
        var _entity = await fetchData(`/authentication/${authId}`);

        if (_entity){
            setIsUniqueDisabled(!_entity.isUnique);
            setIsUnique(_entity.isUnique);
            setAutoSyncronize(_entity.autoSync);
            setTimeZoneOffset(_entity.timeZoneOffset);
            setAuthenticateButtonVisible(false);
            setSaveButtonVisible(true);
            setAuthEntity(_entity);
        }
    };

    const saveAuthentication = async () => {
        authEntity.timeZoneOffset = timeZoneOffset;
        authEntity.isUnique = isUnique;
        authEntity.autoSync = autoSyncronize;

        await putData(`/authentication/${authId}`, authEntity);
        alert("Saved Successfully");
    };

    const startAuthentication = async () => {
        try {
            var session = JSON.parse(localStorage.getItem('session')); 

            var sessionData = {
                "sessionId": session.sessionId,
                "pgoCompanyId": session.pgoCompanyId,
                "pgoProjectId": session.pgoProjectId,
                "pgoScenarioId": session.pgoScenarioId,
                "timeZoneOffset": timeZoneOffset,
                "isUnique": isUnique,
                "autoSync": autoSyncronize
            };

            localStorage.setItem('session', JSON.stringify(sessionData));

            var authResponse = await postData(`/qbo/auth/url`, {});
            window.location.href = authResponse.authUrl;
        } catch (error) {
            // Handle error
        }
    };

    const cancel = async () => {
        if (authId)
        {
            navigate(-1);
        }else{
            window.location.href = PGO_REDIRECT_URL;
        }
    };

    const getTimezoneOffset = () => {
        // Get the current date
        const currentDate = new Date();

        // Get the timezone offset using moment-timezone
        const offsetMinutes = moment.tz(currentDate, moment.tz.guess()).utcOffset();
        console.log(offsetMinutes);
        // Convert minutes to hours
        const offsetHours = offsetMinutes / 60;

        // Return the offset
        return offsetHours;
      };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">Loading...</p>
            </section>
        );
    }else{
        return (
            <div className="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                <div className="text-xl font-medium text-black mb-4">QuickBooks Online Authentication Setup</div>
                <div className="flex items-center">
                    <label>Will your PlanGuru company be connected to a single QuickBooks Company?</label>
                    <select disabled={isUniqueDisabled} className="ml-4 border border-gray-400 px-2 py-1 rounded" value={isUnique} onChange={(event) => setIsUnique(event.target.value === "true")}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                <div className="flex items-center">
                    <input type="checkbox" id="refreshDaily" name="refreshDaily" checked={autoSyncronize} onChange={(event) => setAutoSyncronize(event.target.checked)} className="form-checkbox mr-2" />
                    <label>Do you want to automatically refresh your QuickBooks data daily?</label>
                </div>
                <div className="flex items-center mt-4">
                    <label className="mr-2">Select your timezone:</label>
                    <select id="timezone" name="timezone" className="border border-gray-400 px-2 py-1 rounded" value={timeZoneOffset} onChange={(event) => setTimeZoneOffset(event.target.value)}>
                        <option value="-11">(UTC-12:00) Baker Island, Howland Island</option>
                        <option value="-10">(UTC-11:00) Niue, American Samoa, Midway Atoll</option>
                        <option value="-9">(UTC-10:00) Hawaii-Aleutian Standard Time (HST)</option>
                        <option value="-8">(UTC-09:00) Alaska Standard Time (AKST)</option>
                        <option value="-7">(UTC-08:00) Pacific Standard Time (PST)</option>
                        <option value="-6">(UTC-07:00) Mountain Standard Time (MST)</option>
                        <option value="-5">(UTC-06:00) Central Standard Time (CST)</option>
                        <option value="-4">(UTC-05:00) Eastern Standard Time (EST)</option>
                        <option value="-3">(UTC-04:00) Atlantic Standard Time (AST)</option>
                    </select>
                </div>
                <div className="flex items-center mt-8">
                    <button className="mr-2 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                        Cancel
                    </button>
                    {
                    authenticateButtonVisible && (
                        <button 
                            className="relative bg-transparent hover:bg-transparent mr-2 py-2 px-2 text-white font-bold rounded"
                            onClick={startAuthentication}
                            style={{ 
                                backgroundImage: `url(${connectBtn})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                width: 223,
                                height: 36
                            }}
                        >
                            <span className="absolute inset-0 bg-blue-500 hover:bg-blue-700 opacity-0 transition-opacity duration-300"></span>
                        </button>
                    )
                    }
                    {
                    saveButtonVisible && (
                        <button className="bg-blue-500 hover:bg-blue-700 mr-2 py-2 px-2 text-white font-bold rounded" onClick={saveAuthentication}>
                        Save
                        </button>
                    )
                    }
                </div>
            </div>
          );
    }
}

export default Authenticate;
