import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, putData, postData } from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function EditImportSetup() {
    const [loading, setLoading] = useState(true);
    const [zeroBalanceAccounts, setZeroBalanceAccounts] = useState();
    const [inactiveAccounts, setInnactiveAccounts] = useState();
    const [historicalData, setHistoricalData] = useState();
    const [classList, setClassList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [classFilter, setClassFilter] = useState({});
    const [locationFilter, setLocationFilter] = useState({});
    const [customerFilter, setCustomerFilter] = useState({});
    const [importOption, setImportOption] = useState();
    const [accountingMethod, setAccountingMethod] = useState();
    const [importEntity, setImportEntity] = useState();
    const [progressMessage, setProgressMessage] = useState("Loading...");
    const [pendingJobs, setPendingJobs] = useState([]);
    const [authEntity, setAuthEntity] = useState();
    const navigate = useNavigate();
    const { authId, importId } = useParams();

    useEffect(() => {
        initApp();
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once

    const initApp = async () => {
        var cList = await fetchData(`/qbo/filters/${authId}/classes`);
        setClassList(cList);

        var dList = await fetchData(`/qbo/filters/${authId}/departments`);
        setLocationList(dList);

        var cuList = await fetchData(`/qbo/filters/${authId}/customers`);
        setCustomerList(cuList);
        
        var _import = await fetchData(`/imports/${importId}`)
        setImportEntity(_import);

        var classEntity = cList.find((cls) => cls.id === _import.classFilterId);
        if (classEntity)
            setClassFilter(classEntity);

        var locationEntity = dList.find((cls) => cls.id === _import.locationFilterId);
        if (locationEntity)
            setLocationFilter(locationEntity);

        var customerEntity = cuList.find((cls) => cls.id === _import.customerFilterId);
        if (customerEntity)
            setCustomerFilter(customerEntity);


        setZeroBalanceAccounts(_import.importZeroBalanceAccounts);
        setInnactiveAccounts(_import.importInactiveAccounts);
        setHistoricalData(_import.importHistoricalPeriods);
        setImportOption(_import.importType);
        setAccountingMethod(_import.accountingMethod);

       setLoading(false);
    };

    const cancel = async () => {
        navigate(-1);
    };

    const saveAndReturn = async () => {
        setLoading(true);
        await save();
        var _authEntity = await fetchData(`/authentication/${authId}`);
        window.location.href = `${PGO_REDIRECT_URL}?importZeroBalanceAccounts=${importEntity.importZeroBalanceAccounts}&authenticationId=${authId}&importId=${importEntity.id}&companyName=${_authEntity.qboCompanyName}&class=${importEntity.classFilterName}&department=${importEntity.locationFilterName}&customer=${importEntity.customerFilterName}&autoSync=${_authEntity.autoSync}`;
    };

    const saveAndImport = async () => {
        setLoading(true);
        setProgressMessage("Import in Progress...");
        await save();

        await postData(`/import/${importEntity.id}/run`);

        let intervalId = setInterval(async () => {
            var jobs = await fetchData(`/imports/${importEntity.id}/jobs/in-progress`);
            setPendingJobs(jobs);
            if (!jobs || jobs.length === 0) {
                clearInterval(intervalId); // Stop the interval
                navigate(`/import-connection-options/${authId}/${importEntity.id}`);
            }
        }, 5000); // 10 seconds interval

    };

    const save = async () => {
        importEntity.importZeroBalanceAccounts = zeroBalanceAccounts;
        importEntity.importInactiveAccounts = inactiveAccounts;
        importEntity.importHistoricalPeriods = historicalData;
        importEntity.importType = importOption;
        importEntity.accountingMethod = accountingMethod;

        await putData(`/imports/${importId}`, importEntity);
    };

    const getJobImportType = (importType) => {
        switch (importType)
        {
            case 1:
                return "Processing Balance Sheet...";
                break;
            case 2:
                return "Processing Income Statement...";
                break;
        }
    };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">{progressMessage}</p>
                    {pendingJobs.map(job => (
                        <div key={job.id} className="mt-4 text-gray-700">{getJobImportType(job.importType)}</div>
                    ))}
            </section>
        );
    }else{
        return (
            <>
                <div class="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                    <div class="text-xl font-medium text-black mb-4">QuickBooks Online Import Setup</div>
                    <div class="flex items-center" style={{ display:"none"}} >
                        <input type="checkbox" id="ImportZeroBalanceAccounts" name="ImportZeroBalanceAccounts" class="form-checkbox mr-2" checked={zeroBalanceAccounts} onChange={(event) => setZeroBalanceAccounts(event.target.checked)} />
                        <label for="ImportZeroBalanceAccounts">Import zero balance accounts</label>
                    </div>
                    {/* <div class="flex items-center">
                        <input type="checkbox" id="ImportInactiveAccounts" name="ImportInactiveAccounts" class="form-checkbox mr-2" checked={inactiveAccounts} onChange={(event) => setInnactiveAccounts(event.target.checked)} />
                        <label for="ImportInactiveAccounts">Import inactive accounts</label>
                    </div> */}
                    <div class="flex items-center">
                        <input type="checkbox" id="syncHistoricalData" name="syncHistoricalData" class="form-checkbox mr-2" checked={historicalData} onChange={(event) => setHistoricalData(event.target.checked)}/>
                        <label for="syncHistoricalData">Sync historical data after initial import</label>
                    </div>
                    <div class="flex mt-8">
                        <label>Apply filters:</label>
                        <table class="table-auto">
                            <thead>
                                <tr>
                                    <th class="px-4 py-2"></th>
                                    <th class="px-4 py-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td class="px-4 py-2">Class</td>
                                    <td class="px-4 py-2">
                                        <select 
                                            disabled={true}
                                            class="border border-gray-400 px-2 py-1 rounded"
                                            value={classFilter.id}
                                        >
                                            <option value="">-- none selected --</option>
                                            {classList.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.fullyQualifiedName}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-4 py-2">Location</td>
                                    <td class="px-4 py-2">
                                    <select 
                                            disabled={true}
                                            class="border border-gray-400 px-2 py-1 rounded"
                                            value={locationFilter.id}
                                        >
                                            <option value="">-- none selected --</option>
                                            {locationList.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.fullyQualifiedName}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-4 py-2">Customer</td>
                                    <td class="px-4 py-2">
                                    <select 
                                            disabled={true}
                                            class="border border-gray-400 px-2 py-1 rounded"
                                            value={customerFilter.id}
                                        >
                                            <option value="">-- none selected --</option>
                                            {customerList.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.fullyQualifiedName}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex items-center mt-4">
                        <label for="timezone" class="mr-2">Accounting Method:</label>
                        <input
                            class="mr-2"
                            type="radio"
                            value="1"
                            name="accountingMethod"
                            checked={accountingMethod === 1}
                            onChange={(e) => setAccountingMethod(Number(e.target.value))}
                        /> <span class="mr-2">Accrual</span>
                        <input 
                            class="mr-2"
                            type="radio"
                            value="2"
                            name="accountingMethod"
                            checked={accountingMethod === 2}
                            onChange={(e) => setAccountingMethod(Number(e.target.value))}
                        /> Cash
                    </div>
                    <div class="flex items-center mt-4">
                        <label for="timezone" class="mr-2">Import Options</label>
                        <select id="timezone" name="timezone" class="border border-gray-400 px-2 py-1 rounded"
                        value={importOption}
                        onChange={(e) => setImportOption(parseInt(e.target.value, 10))}>
                            <option selected value="0">Both</option>
                            <option value="1">Balance Sheet</option>
                            <option value="2">Income Statement</option>
                        </select>
                    </div>
                    <div class="flex items-center mt-8">
                        <button class="mr-4 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                            Cancel
                        </button>
                        <button class="bg-blue-500 hover:bg-blue-700 mr-4 py-2 px-2 text-white font-bold rounded" onClick={saveAndReturn}>
                            Save
                        </button>
                        <button class="bg-blue-500 hover:bg-blue-700 mr-4 py-2 px-2 text-white font-bold rounded" onClick={saveAndImport}>
                            Run Import
                        </button>
                    </div>
                </div>
            </>
          );
    }
}

export default EditImportSetup;
