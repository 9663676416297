import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function ImportAppQBO() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // Get specific query parameters
    const pgoCompanyId = queryParams.get('companyId');
    const pgoProjectId = queryParams.get('projectId');
    const pgoScenarioId = queryParams.get('scenarioId');

    const sessionId = uuidv4();

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        try{
         initApp();
        }catch(error){
         console.log("error on init app: ", error);
        }
        
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once

    const initApp = async () => {

        var sessionData = {
            "sessionId": sessionId,
            "pgoCompanyId": pgoCompanyId,
            "pgoProjectId": pgoProjectId,
            "pgoScenarioId": pgoScenarioId
        };

        localStorage.setItem('session', JSON.stringify(sessionData));

        //this should be looking for an authenticated pgoCompanyId record
        var authEntity = await fetchData(`/authentication/pgo/${pgoCompanyId}`);

        //this should be looking for a scenario
        var importScenario = await fetchData(`/imports/scenarioByPgoParams?pgoCompanyId=${pgoCompanyId}&pgoProjectId=${pgoProjectId}&pgoScenarioId=${pgoScenarioId}`);

        if (!authEntity){
            //no company record found
            //need to authenticate and create a new one
            navigate(`/authenticate`);
            return;
        }

        await postData(`/qbo/auth/${authEntity.id}/refresh`);
        
        if (importScenario)
        {
            //TODO: This needs to come from import scenario / import record since that is the source of truth as to which Authentication record should be used for this
            navigate(`/import-connection-options/${importScenario.qboAuthenticationId}/${importScenario.qboCompanyImportId}`);
            return;
        }

        if (authEntity.isUnique){
            
            if (authEntity.imports.length > 0)
            {
                //import scenario's exist for this company
                //navigate to allow either selecting existing import scenario
                //or creating a new one
                navigate(`/select-import-setup/${authEntity.id}`);
                return;
            }else{
                //no import scenario's exist
                //setup a new one
                navigate(`/import-setup/${authEntity.id}`);
                return;
            }
        }else{
            //company exists, but is not unique.  Allow setting up another company
            navigate(`/select-authentication/${pgoCompanyId}`);
            return;
        }
    };

        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">Loading...</p>
            </section>
        );
}

export default ImportAppQBO;
